import { action, computed, observable } from 'mobx';

import { PROMO_CODES } from '../constants/promoCodes';

class CampaignStore {
  @observable currentCampaign = {};
  @observable campaignToShowOnHomePage = {};
  @observable allActiveCampaigns = [];

  getCampaignCheckoutUrl = (campaign) => {
    if (campaign?.affiliateCode === PROMO_CODES.MARCHMADNESS2025) {
      return '/2025-march-madness-promo';
    }

    return '/account/purchase';
  };

  @computed
  get membersCampaign() {
    return this.allActiveCampaigns?.find(
      (campaign) => campaign?.affiliateCode === PROMO_CODES.CLIPSTORE25
    );
  }

  getHomePageCampaignForUser = (user, allActiveCampaigns) => {
    const campaign = allActiveCampaigns?.find(
      (activeCampaign) =>
        activeCampaign?.affiliateCode === PROMO_CODES.MARCHMADNESS2025
    );
    if (campaign?.name) {
      campaign.checkoutUrl = this.getCampaignCheckoutUrl(campaign);
    }

    return campaign;
  };

  findCampaignByCode = (affiliateCode) =>
    this.allActiveCampaigns?.find((c) => c.affiliateCode === affiliateCode);

  @action setCampaignByPage = (currentPathName, anchorToSubscribe) => {
    const campaignMappings = [
      {
        condition: () =>
          currentPathName.includes('/account/purchase') && !anchorToSubscribe,
        action: () => this.findCampaignByCode(PROMO_CODES.VDAY2025),
      },
      {
        condition: () =>
          currentPathName.includes('/account/purchase') &&
          currentPathName.includes(PROMO_CODES.EVENTS_24),
        action: () => this.findCampaignByCode(PROMO_CODES.EVENTS_24) || {},
      },
      {
        condition: () =>
          currentPathName.includes('/account/purchase') &&
          currentPathName.includes(PROMO_CODES.CAMS_BB_50),
        action: () => this.findCampaignByCode(PROMO_CODES.CAMS_BB_50) || {},
      },
      {
        condition: () =>
          currentPathName.includes('/account/purchase') &&
          currentPathName.includes(PROMO_CODES.PORN_DUDE_50),
        action: () => this.findCampaignByCode(PROMO_CODES.PORN_DUDE_50) || {},
      },
      {
        condition: () => currentPathName.includes('/lifetime-promo-playboy'),
        action: () =>
          this.allActiveCampaigns?.find((c) =>
            c.affiliateCode?.includes(PROMO_CODES.LIFETIMEPBNOV24)
          ) || {},
      },
      {
        condition: () =>
          currentPathName.includes('/lifetime-promo') &&
          currentPathName.includes(PROMO_CODES.NNLIVEMRSK6),
        action: () =>
          this.allActiveCampaigns?.find((c) =>
            c.affiliateCode?.includes(PROMO_CODES.NNLIVEMRSK6)
          ) || {},
      },
      {
        condition: () =>
          currentPathName.includes('/lifetime-promo') &&
          currentPathName.includes(PROMO_CODES.CAMS_BB),
        action: () =>
          this.allActiveCampaigns?.find(
            (c) => c.affiliateCode === PROMO_CODES.CAMS_BB
          ) || {},
      },
      {
        condition: () =>
          currentPathName.includes('/lifetime-promo') &&
          currentPathName.includes(PROMO_CODES.PORN_DUDE),
        action: () =>
          this.allActiveCampaigns?.find(
            (c) => c.affiliateCode === PROMO_CODES.PORN_DUDE
          ) || {},
      },
      {
        condition: () => currentPathName === '/promo/asn-awards',
        action: () => this.findCampaignByCode(PROMO_CODES.ASNAW22) || {},
      },
      {
        condition: () => currentPathName.includes('/2025-march-madness-promo'),
        action: () =>
          this.findCampaignByCode(PROMO_CODES.MARCHMADNESS2025) || {},
      },
    ];

    this.currentCampaign =
      campaignMappings.find(({ condition }) => condition())?.action() || {};
  };

  @action setCurrentCampaign = (user, allActiveCampaigns) => {
    this.campaignToShowOnHomePage = this.getHomePageCampaignForUser(
      user,
      allActiveCampaigns
    );
    this.allActiveCampaigns = allActiveCampaigns;
  };
}

export default new CampaignStore();
