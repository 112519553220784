export const PROMO_CODES = {
  YOUNG_SWINGERS_PROMO: 'SWINGERS_PROMO',
  EASTER_PROMO: 'EASTER_PROMO_2019',
  INDEPENDENCE_PROMO: 'INDEPENDENCE_PROMO_2019',
  BACK_TO_SCHOOL_PROMO: 'BACK_TO_SCHOOL_PROMO_2019',
  CAM4_SOURCE: 'CAM4_SOURCE',
  CAM4_ANCHOR_SOURCE: 'CAM4_ANCHOR_SOURCE',
  PROMO_ANCHOR_SOURCE: 'PROMO_ANCHOR_SOURCE',
  NNLIVEMRSK: 'NNLIVEMRSK',
  NNLIVEMRSK4: 'NNLIVEMRSK4',
  ASNAW22: 'ASNAW22',
  TRIALFANCLUBS: 'TRIALFANCLUBS',
  TRIALFANCLUBS2023: 'trialfanclubs2023',
  CYBERMONDAY2022: 'CYBERMONDAY2022',
  XMASS2022: 'XMASS2022',
  FREESIGNUP15: 'FREESIGNUP15',
  BTS2023: 'BTS2023',
  BLACKFRIDAY23: 'BLACKFRIDAY23',
  CYBERMONDAY23: 'CYBERMONDAY23',
  XMAS23: 'XMAS23',
  CHRISTMAS_FANZONE_2023: 'CHRISTMAS_FANZONE_2023',
  NNLIVEMRSK5: 'NNLIVEMRSK5',
  VALENTINE24: 'VALENTINE24',
  NNEASTER2024: 'NNEASTER2024',
  JULY42024: '4JULY2024',
  MOTHER2024: 'MOTHER2024',
  PLAYBOY_PROMO_2024: 'PLAYBOY_PROMO_2024',
  EVENTS_24: 'EVENTS24',
  ASNWEEK2024: 'ASNWEEK2024',
  NAKEDNEWS25: 'NAKEDNEWS25',
  TRIALFANCLUBS2025: 'TRIALFANCLUBS2025',
  CAMS_BB_50: 'CAMS_BB_50',
  PORN_DUDE_50: 'PORN_DUDE_50',
  NNLIVEMRSK6: 'NNLIVEMRSK6',
  CAMS_BB: 'CAMS_BB',
  PORN_DUDE: 'PORN_DUDE',
  LIFETIMEPBNOV24: 'LIFETIMEPBNOV24',
  BLACKFRIDAY24AFF: 'BLACKFRIDAY24AFF',
  BLACKFRIDAY24: 'BLACKFRIDAY24',
  CYBERMONDAY24AFF: 'CYBERMONDAY24AFF',
  CYBERMONDAY24: 'CYBERMONDAY24',
  XMAS2024: 'XMAS2024',
  NNLXMAS2024: 'NNLXMAS2024',
  CLIPSTORE25: 'CLIPSTORE25',
  VDAY2025: 'VDAY2025',
  MARCHMADNESS2025: 'MARCHMADNESS2025',
};

export const EXPIRED_PROMOS = [
  PROMO_CODES.EASTER_PROMO,
  PROMO_CODES.YOUNG_SWINGERS_PROMO,
];
